$(document).ready(function () {
  if (window.innerWidth < 600) {
    $(".animated").removeClass("fadeInLeft").addClass("fadeInLeft");
    $(".animated").removeClass("fadeInRight").addClass("fadeInLeft");
  }
  // Activate WOW.js plugin for animation on scrol
  new WOW().init();
});

document.getElementById("scrollUp").addEventListener("click", (evt) => {
  evt.preventDefault();
  window.scrollTo(0, 0);
});
